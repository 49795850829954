import { omitBy } from 'lodash-es'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig() ?? {}

export const whichEnvironment = publicRuntimeConfig?.NEXT_PUBLIC_ENVIRONMENT as 'local' | 'staging' | 'production'
export const isLocalEnvironment = publicRuntimeConfig?.NEXT_PUBLIC_ENVIRONMENT === 'local'
export const isStagingEnvironment = publicRuntimeConfig?.NEXT_PUBLIC_ENVIRONMENT === 'staging'
export const isProductionEnvironment = publicRuntimeConfig?.NEXT_PUBLIC_ENVIRONMENT === 'production'
export const isDevelopmentMode = process.env.NODE_ENV === 'development'
export const isProductionMode = process.env.NODE_ENV === 'production'

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (obj: any): obj is Function => typeof obj === 'function'

export const isClient = typeof window !== 'undefined' && window?.document && !!window?.document.createElement
export const isSSR = !isClient

const isEmptyArray = (value: any) => Array.isArray(value) && value.length === 0
const isEmptyValue = (value: any) => value === undefined || value === null || value === '' || isEmptyArray(value)
export const deleteEmptyArrays = (obj: any) => omitBy(obj, isEmptyArray)
export const deleteEmptyValues = (obj: any) => omitBy(obj, isEmptyValue)

const hasOwn = Object.prototype.hasOwnProperty

/**
 * Object.is() polyfill
 */
export function is(x: any, y: any) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y
  } else {
    return x !== x && y !== y
  }
}

export function shallowEqual(objA: any, objB: any) {
  if (is(objA, objB)) return true

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false
  }

  if (Array.isArray(objA) && Array.isArray(objB)) {
    if (objA.length !== objB.length) return false

    for (let i = 0; i < objA.length; i++) {
      if (!is(objA[i], objB[i])) {
        return false
      }
    }

    return true
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) return false

  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false
    }
  }

  return true
}
