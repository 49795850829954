import { GetServerSidePropsContext, NextApiRequest, NextApiResponse } from 'next'

import { isSSR } from '@/shared/utils'

export interface JwtToken {
  id: any
  name: string
  email: string
  organisationId: string
  organisationName: string
  iat: number
  exp: number
}

export const decodeToken = (token?: string | null): JwtToken | null => {
  if (!token) return null

  const payload = token.split('.')[1]
  const buff = Buffer.from(payload, 'base64')
  const decodedPayload = JSON.parse(buff.toString('utf-8'))

  const id = decodedPayload?.['user_id']
  const name = decodedPayload?.['user_name']
  const email = decodedPayload?.['user_email']
  const organisationId = decodedPayload?.['organisation_id']
  const organisationName = decodedPayload?.['organisation_name']
  const iat = decodedPayload?.['iat']
  const exp = decodedPayload?.['exp']

  return { id, name, email, organisationId, organisationName, iat, exp }
}

export const getToken = async (ctx?: GetServerSidePropsContext) => {
  if (!isSSR) return null

  if (!ctx?.req || !ctx?.req?.headers) return null

  const { default: Cookies } = await import('cookies')
  const cookies = new Cookies(ctx?.req as any, ctx?.res as any)
  return cookies.get('access-token')
}

export const getReqToken = async (req: NextApiRequest, res: NextApiResponse) => {
  if (!req || !req?.headers) return null

  const { default: Cookies } = await import('cookies')
  const cookies = new Cookies(req, res)
  const cookiesToken = cookies.get('access-token')
  const headersToken = req?.headers?.authorization?.split(' ')?.[1]

  return cookiesToken ?? headersToken
}

export const setToken = async (ctx: GetServerSidePropsContext, token: string) => {
  if (!isSSR) return
  if (!token) return

  const { default: Cookies } = await import('cookies')
  const cookies = new Cookies(ctx?.req as any, ctx?.res as any)

  cookies.set('access-token', token, {
    path: '/',
    maxAge: 14 * 24 * 60 * 60 * 1000, // 14 days
    httpOnly: true,
  })
}

export const clearToken = async (ctx: GetServerSidePropsContext) => {
  if (!isSSR) return

  const { default: Cookies } = await import('cookies')
  const cookies = new Cookies(ctx?.req as any, ctx?.res as any)

  cookies.set('user.logged_in', '0', {
    path: '/',
    maxAge: 0,
    httpOnly: false,
    domain: 'useorbit.io',
  })

  cookies.set('access-token', '', {
    path: '/',
    maxAge: 0,
    httpOnly: true,
  })
}

export const getLocalUser = (): JwtToken | null => {
  if (isSSR) return null

  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : {}
  return user
}

export const setLocalUser = (token: string) => {
  const user = decodeToken(token)

  localStorage?.setItem('user', JSON.stringify(user))
  localStorage?.setItem('user.id', user?.id)
  localStorage?.setItem('user.email', user?.email ?? '')
}
