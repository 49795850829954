import { ExternalToast, Toaster, toast as sonner } from 'sonner'

import { useThemeContext } from '@/contexts'

import { BaseToastProps } from './BaseToast'
import { InfoToast } from './InfoToast'
import { ActionToast } from './ActionToast'

export const Toast = () => {
  const { isDarkMode } = useThemeContext()

  return <Toaster className="Toaster" theme={isDarkMode ? 'dark' : 'light'} duration={2500} closeButton={false} />
}

const info = (msg: BaseToastProps['msg'], options: ExternalToast = {}) =>
  sonner.info(<InfoToast msg={msg} />, {
    position: 'bottom-right',
    duration: 3000,
    ...options,
  })

const actionable = (
  msg: BaseToastProps['msg'],
  actions: BaseToastProps['toastActions'],
  options?: ExternalToast | undefined,
) =>
  sonner.info(<ActionToast msg={msg} toastActions={actions} />, {
    position: 'bottom-right',
    duration: 5000,
    ...options,
  })

export const toast = {
  ...sonner,
  info,
  actionable,
}
