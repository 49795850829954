import { createGlobalStyle, css, th } from '@xstyled/styled-components'

export const GlobalStyles = createGlobalStyle`
	${css`
    html {
      /* scrollbar width */
      *::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      /* scrollbar track */
      *::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
      }

      /* scrollbar handle */
      *::-webkit-scrollbar-thumb {
        background: ${th.color('grey.16')};
        border-radius: 5px;
      }

      /* scrollbar handle (hover) */
      *::-webkit-scrollbar-thumb:hover {
        background: ${th.color('grey.64')};
        cursor: pointer;
      }
    }

    body {
      max-height: 100vh;
      background-color: ${th.color('grey.4')};
      font-size: 14px;

      *::selection {
        background: rgba(116, 119, 249, 0.15);
      }

      *::-moz-selection {
        background: rgba(116, 119, 249, 0.15);
      }

      &.dragging {
        user-select: none;
      }
    }

    strong {
      font-weight: 600;
    }

    [type='button'],
    [type='reset'],
    [type='submit'],
    button {
      :not([role='checkbox']) {
        -webkit-appearance: button-bevel;
      }
    }

    blockquote {
      position: relative;
      padding-left: 15px;

      :before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 100%;
        background-color: ${th.color('purple.32')};
        border-radius: 5px;
      }
    }

    div[data-radix-portal] {
      z-index: 2 !important;
    }

    /* Slate/Plate style overrides */

    *[data-slate-string='true'] {
      word-break: break-word;
    }

    *[data-slate-editor='true'] {
      outline: none;
    }

    .slate-h3::before {
      opacity: 0.3;
      color: ${th.color('grey.100')};
    }

    .slate-h4 {
      color: ${th.color('grey.100')};
      font-weight: 600 !important;
      font-size: 16px !important;
    }

    .slate-h5 {
      color: ${th.color('grey.100')};
      font-weight: 600 !important;
      font-size: 14px !important;
    }

    .slate-h6 {
      color: ${th.color('grey.100')};
      font-weight: 600 !important;
      font-size: 12px !important;
    }

    .slate-li {
      width: 100%;
    }

    .slate-Draggable {
      opacity: 0.999;

      > div {
        overflow: visible !important;
      }

      .slate-Draggable-blockToolbar {
        opacity: 0;
        transition: opacity 200ms;
        transition-timing-function: cubic-bezier(0.58, 1.28, 0.95, 0.24);
      }

      :hover {
        .slate-Draggable-blockToolbar {
          opacity: 1;
        }
      }
    }

    div[data-tippy-root] {
      display: none;
    }

    .slate-BalloonToolbar {
      transition: none;

      &[visible='false'] {
        opacity: 0 !important;
      }

      background-color: ${th.color('white')} !important;
      border-radius: 5px !important;
      border: 1px solid !important;
      border-color: ${th.color('grey.12')} !important;
      box-shadow: ${th.shadow('normal')} !important;
      padding: 0 5px !important;
      height: 42px;
      z-index: 3 !important;
    }

    .slate-mention_input {
      background-color: unset !important;
      box-shadow: none !important;
      padding: 0px !important;
      margin: 0px !important;
      font-family: inherit !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      background-color: ${th.color('grey.4')} !important;
      padding: 0px !important;

      ::before {
        content: '@';
        color: ${th.color('grey.100')};
      }
    }

    .slate-selection-area {
      background: #7477f92c;

      z-index: 10;
      transition: background-color 0.11s ease-in-out;
    }

    .slate-selected {
      background-color: #9697f82c !important;
      border-radius: 5px;

      transition: background-color 0.11s ease-in-out;
    }

    .slate-selectable {
      margin-bottom: 2px;
      padding-left: 2px;
      padding-right: 2px;

      transition: background-color 0.11s ease-in-out;
    }

    [class^='PlateTablePopover'] {
      position: static !important;
      width: 140px !important;
      top: 0 !important;
      right: -180px !important;
      left: unset !important;
      bottom: unset !important;

      button {
        width: 100% !important;
      }
    }

    //sonner
    .Toaster {
      --width: unset !important;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 16px;
      z-index: 10;
      bottom: 16px;

      &[data-x-position='center'] {
        justify-content: center;
      }

      &[data-x-position='left'] {
        justify-content: flex-start;
      }

      &[data-x-position='right'] {
        justify-content: flex-end;
      }

      li {
        padding: 16px;
        min-width: 300px;
        transition-duration: 220ms;

        * {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }

    .Unselect_svg__Background {
      fill: ${th.color('white')} !important;
    }

    /* Radix-UI style overrides */
    [role='dialog'] {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    /* React Flow style overrides */
    .react-flow__attribution {
      display: none !important;
    }

    .react-flow__node-objective,
    .react-flow__node-opportunity,
    .react-flow__node-solution {
      transition: transform 0.1s ease-in-out;
    }

    .react-flow__minimap {
      background-color: ${th.color('white')} !important;
      border: 1px solid ${th.color('grey.8')};
      box-shadow: ${th.shadow('light')};
      border-radius: 5px;
    }

    .react-flow__minimap-node {
      fill: ${th.color('grey.8')} !important;
    }

    .react-flow__minimap-mask {
      fill: ${th.color('grey.4')} !important;
    }

    .react-flow__controls {
      position: absolute;
      top: 15px !important;
      right: 15px !important;
      bottom: unset !important;
      left: unset !important;

      z-index: 5;
      display: flex;

      height: 27px !important;
      max-height: 27px !important;
      width: 107px !important;
      max-width: 107px !important;
      border-radius: 5px;
      border: 1px solid ${th.color('grey.12')};

      box-shadow: extraLight !important;
      overflow: hidden;

      @media (max-width: 768px) {
        display: none;
      }

      button {
        outline: none;
        padding: 0;
        width: 26px;
        height: 26px;
        background-color: ${th.color('white')};

        transition: background-color 0.2s ease-in-out;

        :hover {
          background-color: ${th.color('grey.4')};
        }

        svg {
          width: 12px;
          height: 12px;
          max-width: unset;
          max-height: unset;
        }

        path {
          fill: ${th.color('grey.48')};
        }

        :last-child {
          border-right: none !important;
        }

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 26px;
          min-height: 26px;

          > button {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 26px;
            min-height: 26px;
          }
        }
      }
    }
  `}
` as any
