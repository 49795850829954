import * as RadixSeparator from '@radix-ui/react-separator'
import styled, { SystemProps, system, th } from '@xstyled/styled-components'

export const Separator = styled(RadixSeparator.Root)<SystemProps & { vertical?: boolean }>`
  height: ${({ vertical }) => (vertical ? '100%' : '1px')};
  width: ${({ vertical }) => (vertical ? '1px' : '100%')};
  background-color: ${th.color('grey.12')};
  margin-left: 0 !important;
  margin-right: 0 !important;

  ${system};
`
