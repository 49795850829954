import styled, {
  alignSelf,
  compose,
  css,
  maxWidth,
  minWidth,
  space,
  system,
  th,
  typography,
} from '@xstyled/styled-components'

import { variant } from '@/shared/utils/styled'
import { typographySizes } from '@/theme/tokens/typography'

export const textSystemProps = compose(space, minWidth, maxWidth, typography, alignSelf)

const textStyles = {
  none: {},
  ellipsis: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
}
const textStyleVariant = variant({
  prop: 'textStyle',
  default: 'none',
  variants: textStyles,
})

const textSizeVariant = variant({
  prop: 'size',
  default: 'md',
  variants: typographySizes,
})

type TextStyleProp = { readonly textStyle?: keyof typeof textStyles }

export const Text = styled.divBox<TextStyleProp>`
  color: ${th.color('grey.100')};

  ${textStyleVariant as any}
  ${textSizeVariant as any}

  line-height: normal;

  /* to take precedence over Link variants */
  && {
    ${system}
  }
`

export const H1 = styled(Text).attrs(() => ({ as: 'h1', size: 'xxl' }))``
export const H2 = styled(Text).attrs(() => ({ as: 'h2', size: 'xl' }))``
export const H3 = styled(Text).attrs(() => ({ as: 'h3', size: 'lg' }))``
export const H4 = styled(Text).attrs(() => ({ as: 'h4', size: 'md' }))``
