import React from 'react'

import { BaseToast, BaseToastProps } from './BaseToast'

interface ActionToastProps {
  msg: BaseToastProps['msg']
  toastActions: BaseToastProps['toastActions']
}

export const ActionToast = ({ msg, toastActions }: ActionToastProps) => {
  return <BaseToast title="Action" msg={msg} toastActions={toastActions} />
}
