import { css, th } from '@xstyled/styled-components'

export const typographySizes = {
  none: {},
  sm: css`
    font-size: ${th.fontSize('sm')};
    line-height: 18px;
  `,
  md: css`
    font-size: ${th.fontSize('md')};
    line-height: 21px;
  `,
  lg: css`
    font-size: ${th.fontSize('lg')};
    font-weight: ${th.fontWeight('semibold')};
    line-height: 27px;
  `,
  xl: css`
    font-size: ${th.fontSize('xl')};
    font-weight: ${th.fontWeight('semibold')};
    line-height: 36px;
  `,
  xxl: css`
    font-size: ${th.fontSize('xxl')};
    font-weight: ${th.fontWeight('semibold')};
    line-height: 45px;
  `
}
