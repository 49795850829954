export const truncate = (str: string | null | undefined | React.ReactElement, maxLength: number) => {
  if (!str) return ''
  if (typeof str !== 'string') return str as React.ReactElement

  if (str.length > maxLength) return str.substring(0, maxLength) + '...'

  return str
}

export const removeDashesAndParentheses = (str: string) => {
  return str.replace(/[-()|]/g, '')
}

export const removeSpecialCharacters = (str: string) => {
  return str.replace(/[^\w\s]/gi, '')
}

export const fileNameCleaner = (fileName: string) => {
  const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.')
  const fileNameWithoutSpecialCharacters = removeSpecialCharacters(fileNameWithoutExtension)
  const fileNameWithoutExtraSpaces = fileNameWithoutSpecialCharacters.replace(/ +/g, ' ')
  return fileNameWithoutExtraSpaces.replace(/ /g, '_').toLocaleLowerCase()
}
