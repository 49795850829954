import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ColorModeProvider, Preflight, ThemeProvider } from '@xstyled/styled-components'
import { Session } from 'next-auth'
import { SessionProvider as NextAuthSessionProvider, getSession } from 'next-auth/react'
import type { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import React from 'react'

import Root from '@/app/Root'
import { queryClientOptions } from '@/graphql/queryClient'
import { Condition } from '@/kit'
import { ErrorBoundary } from '@/shared/ui'
import { isLocalEnvironment } from '@/shared/utils'
import { GlobalStyles } from '@/styles'
import '@/styles/globals.css'
import { theme } from '@/theme'

type AppPropsWithLayout = AppProps & {
  Component: any
  pageProps: AppProps['pageProps'] & {
    session: Session
    dehydratedState?: any
  }
}

const interFont = Inter({ subsets: ['latin'], display: 'swap', variable: '--font-inter' })

function App({ Component, pageProps }: AppPropsWithLayout) {
  const queryClientRef = React.useRef<any>(new QueryClient(queryClientOptions))
  const [session, setSession] = React.useState<Session | null>()

  const handleGetSession = React.useCallback(async () => {
    const authSession = await getSession()
    setSession(authSession)
  }, [])

  React.useEffect(() => {
    handleGetSession()
  }, [handleGetSession])

  const queryClient = queryClientRef.current
  const isAuthorized = !!session?.user

  const getLayout = Component.getLayout ?? ((page: any) => page)

  return (
    <div className={interFont.className}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps?.dehydratedState}>
          <Condition when={false && isLocalEnvironment}>
            <ReactQueryDevtools initialIsOpen={false} />
          </Condition>
          <ThemeProvider theme={theme}>
            <ColorModeProvider>
              <ErrorBoundary>
                <Preflight />
                <GlobalStyles />
                <NextAuthSessionProvider session={session}>
                  <Root isAuthorized={isAuthorized}>{getLayout(<Component {...pageProps} />)}</Root>
                </NextAuthSessionProvider>
              </ErrorBoundary>
            </ColorModeProvider>
          </ThemeProvider>
        </Hydrate>
      </QueryClientProvider>
    </div>
  )
}

export default App
