import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React from 'react'

import {
  IGetUserQuery,
  IUpdatePasswordMutationVariables,
  IUpdateProfileMutationVariables,
  IUpsertUserPreferencesMutationVariables,
  useGetLoggedInUserQuery,
  useGetUserPreferencesQuery,
  useGetUserQuery,
  useGetWorkspaceQuery,
  useUpdatePasswordMutation,
  useUpdateProfileMutation,
  useUpsertUserPreferencesMutation,
} from '@/generated/graphql'
import { requestClient } from '@/graphql'
import { isClient } from '@/shared/utils'
import { getUserWorkspaceId } from '@/utils'

import { LoggedUser, User, UserPreferences } from './types'

export const useLoggedInUser = (): LoggedUser => {
  const isAuthPath = isClient && window?.location?.pathname?.startsWith('/auth')
  const isInvitationPath = isClient && window?.location?.pathname?.startsWith('/invitation')

  const isQueryEnabled = !isAuthPath && !isInvitationPath

  const { data } = useGetLoggedInUserQuery(requestClient, {}, { enabled: isQueryEnabled })

  return data?.user
}

export const useUser = (): [{ user: User; fetching: boolean; error?: any }, Store.RefetchQueryFn] => {
  const loggedInUser = useLoggedInUser()

  const userId = loggedInUser?.userId

  const { data, isLoading, error, refetch } = useGetUserQuery(requestClient, { userId }, { enabled: !!userId })

  const user = data?.user

  return [{ user: user as User, fetching: isLoading, error }, refetch]
}

export type UserRole = 'owner' | 'collaborator' | 'contributor' | 'viewer'

const roleHierarchy = {
  owner: 0,
  collaborator: 1,
  contributor: 2,
  viewer: 3,
}

interface IsRoleAtLeastParams {
  minRole: UserRole
  role: UserRole
}

export const isRoleAtLeast = ({ role, minRole }: IsRoleAtLeastParams) => {
  return roleHierarchy[role] <= roleHierarchy[minRole]
}

export const useUserRole = () => {
  const [{ user }] = useUser()

  const workspaceId = getUserWorkspaceId(user)
  const userWorkspace = user?.userWorkspaces?.find((userWorkspace) => userWorkspace.workspace?.id === workspaceId)

  const role = userWorkspace?.role as UserRole

  const isCreatorAdmin = role === 'owner'
  const isCreator = role === 'collaborator'
  const isContributor = role === 'contributor'
  const isViewer = role === 'viewer'

  const handleIsRoleAtLeast = React.useCallback((minRole: UserRole) => isRoleAtLeast({ role, minRole }), [role])

  return {
    role: userWorkspace?.role as UserRole,
    isCreatorAdmin,
    isCreator,
    isContributor,
    isViewer,
    isRoleAtLeast: handleIsRoleAtLeast,
  }
}

export const useUpdateUser = () => {
  const [{ user }] = useUser()

  const userId = user?.id
  const workspaceId = getUserWorkspaceId(user)

  const queryClient = useQueryClient()

  const { mutateAsync: updateUser } = useUpdateProfileMutation(requestClient, {
    onMutate: async (vars) => {
      await queryClient.cancelQueries(useGetUserQuery.getKey({ userId }))

      const previousUser = queryClient.getQueryData<IGetUserQuery>(useGetUserQuery.getKey({ userId }))

      queryClient.setQueryData<IGetUserQuery>(useGetUserQuery.getKey({ userId }), (oldData) => {
        const user = oldData?.user

        const updatedUser = { ...user, ...vars?.set } as User

        return { user: updatedUser, __typename: 'query_root' }
      })

      return { previousUser }
    },
    onError: (err, vars, context) => {
      queryClient.setQueryData<IGetUserQuery>(useGetUserQuery.getKey({ userId }), context?.previousUser)
    },
    onSettled: () => {
      queryClient.invalidateQueries(useGetUserQuery.getKey({ userId: user?.id }))
      queryClient.invalidateQueries(useGetWorkspaceQuery.getKey({ id: workspaceId }))
    },
  })

  const updateUserCallback = React.useCallback(
    async (variables: IUpdateProfileMutationVariables) => {
      const updatedUser = { ...user, ...variables?.set } as User

      if (!_.isEqual(updatedUser, user)) {
        try {
          await updateUser(variables)
          return true
        } catch (error) {
          return false
        }
      }

      return true
    },
    [user, updateUser],
  )

  return updateUserCallback
}

export const useChangePassword = () => {
  const { mutateAsync: changePassword } = useUpdatePasswordMutation(requestClient)

  const changePasswordCallback = React.useCallback(
    async (vars: IUpdatePasswordMutationVariables) => {
      await changePassword(vars)
    },
    [changePassword],
  )

  return changePasswordCallback
}

export const useUpdateUserLastActiveAt = () => {
  const [{ user }] = useUser()

  const queryClient = useQueryClient()

  const { mutateAsync: updateUser } = useUpdateProfileMutation(requestClient, {
    onSuccess: () => {
      queryClient.invalidateQueries(useGetUserQuery.getKey({ userId: user?.id }))
    },
  })

  const currentUserId = user?.id
  const workspaceId = getUserWorkspaceId(user)

  const router = useRouter()

  const updateLastActiveAt = React.useCallback(async () => {
    if (!currentUserId || !workspaceId) return

    try {
      await updateUser({
        id: user?.id,
        set: { lastActiveAt: new Date(), lastVisitedWorkspaceId: workspaceId },
      })

      return true
    } catch (error) {
      return false
    }
  }, [currentUserId, updateUser, workspaceId, user?.id])

  React.useEffect(() => {
    const handleRouteChange = async () => {
      const oneMinuteAgo = dayjs().subtract(1, 'minute')
      const wasActiveAtWithinTheLastMinute = dayjs(user?.lastActiveAt).isAfter(oneMinuteAgo)

      if (wasActiveAtWithinTheLastMinute) return

      await updateLastActiveAt()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, updateLastActiveAt])
}

export const useGetUserPreferences = (): Store.GetStoreCallbackTuple<{
  preferences: UserPreferences
}> => {
  const [{ user }] = useUser()
  const userId = user?.id
  const workspaceId = getUserWorkspaceId(user)

  const { data, isLoading, error, isFetched, refetch } = useGetUserPreferencesQuery(
    requestClient,
    { userId, workspaceId },
    {
      enabled: !!userId && !!workspaceId,
    },
  )

  const preferences = data?.user?.preferences?.[0] as UserPreferences

  return [{ preferences, fetching: isLoading, error, hasRequestedData: isFetched }, refetch]
}

export const useUpsertUserPreferences = () => {
  const [{ preferences: initialPreferences }] = useGetUserPreferences()
  const [{ user }] = useUser()
  const userId = user?.id

  const workspaceId = getUserWorkspaceId(user)
  const organisationId = user?.userOrganisations?.filter((uo) =>
    uo.organisation.workspaces.some((w) => w.id === workspaceId),
  )[0]?.organisation?.id

  const queryClient = useQueryClient()

  const { mutateAsync: upsertUserPreferences } = useUpsertUserPreferencesMutation(requestClient, {
    onSettled: () => {
      queryClient.invalidateQueries(useGetUserPreferencesQuery.getKey({ userId, workspaceId }))
    },
  })

  const upsertUserPreferencesCallback = React.useCallback(
    async ({ preferences }: IUpsertUserPreferencesMutationVariables) => {
      await upsertUserPreferences({
        preferences: {
          userId,
          workspaceId,
          organisationId,
          updatedAt: new Date(),
          ...initialPreferences,
          ...preferences,
        },
      })
    },
    [upsertUserPreferences, userId, workspaceId, organisationId, initialPreferences],
  )

  return upsertUserPreferencesCallback
}
