import React from 'react'

import * as S from './Loader.styled'

interface LoaderProps {
  className?: string
  variant: S.LoaderVariantProps['variant']
  size?: number
  xss?: Omit<SCProps<typeof S.Container>, 'variant' | 'size'>
}

export const Loader = ({ variant, size, className, ...xss }: LoaderProps) => {
  return (
    <div className={className}>
      <S.Container variant={variant} size={size} {...xss}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </S.Container>
    </div>
  )
}
