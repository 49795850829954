import { GraphQLClient } from 'graphql-request'
import { GetServerSidePropsContext } from 'next'
import getConfig from 'next/config'

import { getToken } from '@/utils'

import { GRAPHQL_API_URL } from './constants'

const { publicRuntimeConfig } = getConfig?.() || {}
const NEXT_PUBLIC_CLIENT_URL = publicRuntimeConfig?.NEXT_PUBLIC_CLIENT_URL ?? process.env.NEXT_PUBLIC_CLIENT_URL

export const requestClient = new GraphQLClient(GRAPHQL_API_URL, {
  headers: { 'x-forwarded-origin': NEXT_PUBLIC_CLIENT_URL },
})

export const authorizeRequestClient = async (ctx: GetServerSidePropsContext) => {
  const token = await getToken(ctx)
  if (!token) return false

  requestClient.setHeader('authorization', `Bearer ${token}`)

  return true
}
