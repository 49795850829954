import { setUser, setContext, setTags } from '@sentry/nextjs'
import React from 'react'

import { isClient } from '@/shared/utils'

import { JwtToken } from '../utils'

export const identifyUser = (token?: JwtToken | null): void => {
  if (!isClient) return
  if (!token) return

  try {
    const { id, email } = token

    setUser({ id, email })
    setContext('userContext', { id, email })
    setTags({ id, email })
  } catch {
    setUser(null)
  }
}

export const useInit = () => {
  React.useEffect(() => {
    const localToken = localStorage?.getItem('user')

    if (localToken) {
      identifyUser(JSON.parse(localToken) as JwtToken)
    }
  }, [])
}
