import React from 'react'

import { useFiles } from '@/store'
import { useDebounce } from '@/shared/hooks'

import { BackgroundTaskToast } from './BackgroundTaskToast'

export const UploadingFileToast = () => {
  const { files, hasUploadingFiles } = useFiles()
  const isUploading = hasUploadingFiles
  const uploadingFile = files.find((file) => file?.progress < 100)

  const debounceIsUploading = useDebounce(isUploading, 2500)

  return (
    <BackgroundTaskToast
      isVisible={isUploading || debounceIsUploading}
      progress={uploadingFile?.progress}
      message={isUploading ? 'Uploading file...' : `Upload complete!`}
    />
  )
}
