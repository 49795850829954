import { css, th } from '@xstyled/styled-components'

import { typographySizes } from '@/theme/tokens/typography'

import { assign, is, getThemeValue, merge } from './xstyled'

export const variant =
  ({ key = null, default: defaultValue, variants = {}, prop = 'variant' }: any) =>
  (props: any) => {
    const themeVariants = is(key) ? getThemeValue(props, key) : null
    const computedVariants = merge(assign({}, variants), themeVariants)
    const value = props[prop] !== undefined ? props[prop] : defaultValue
    const result = getThemeValue(props, value, computedVariants)
    return result as any
  }

// sizes of buttons, inputs, datepickers, comboboxes, etc
export const sizeVariants = {
  none: {},
  xs: css`
    padding: 0 10px;
    min-height: ${th('sizes.xs')}px;
  `,
  sm: css`
    padding: 0 10px;
    min-height: ${th('sizes.sm')}px;
  `,
  md: css`
    padding: 0 10px;
    min-height: ${th('sizes.md')}px;
    ${typographySizes.md}
  `,
  textarea: css`
    padding: 10px;
    min-height: 100px;
  `,
}

export const sizeVariant = variant({
  prop: 's',
  default: 'md',
  variants: sizeVariants,
})
export type SizeVariantProps = { readonly s?: keyof typeof sizeVariants }

export type ErrorStylesProps = { readonly error?: boolean }
export const errorStyles = (props: ErrorStylesProps): any =>
  props.error &&
  css`
    &&& {
      border-color: ${th.color('red.error')};
    }
  `

export const placeholderStyles = css`
  color: ${th.color('grey.40')};
`

export const maxLines = (maxLinesNumber: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${maxLinesNumber};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
