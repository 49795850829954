import { x } from '@xstyled/styled-components'

import { StringUtils } from '@/utils'

import { Button } from '../Button'
import { Condition } from '../Condition'
import * as S from './BaseToast.styled'

interface ToastAction {
  text?: string | JSX.Element
  icon?: JSX.Element
  onClick?: (e?: React.MouseEvent) => void
  render?: () => React.ReactNode
}

export interface BaseToastProps {
  title: string
  msg: string | JSX.Element
  toastActions?: ToastAction[]
}

export const BaseToast = ({ title, msg, toastActions = [] }: BaseToastProps) => (
  <S.$ToastContainer>
    <S.$ToastMessage title={title} maxW={toastActions && toastActions?.length > 0 ? '400px' : '100%'}>
      {StringUtils.truncate(msg, 150)}
    </S.$ToastMessage>
    <Condition when={!!toastActions && toastActions.length > 0}>
      <x.div display="flex" alignItems="center" gap="8px" ml="auto">
        {toastActions?.map((toastAction, idx) => {
          if (toastAction?.render) return toastAction.render()

          return (
            <Button
              key={`toast-action-${idx}`}
              text={toastAction?.text}
              childrenPosition="before"
              onClick={toastAction?.onClick}
              variant="secondary"
              minWidth="fit-content"
              minHeight="30px"
              fontSize={12}
            >
              <Condition when={!!toastAction.icon}>{<x.div mr={10}>{toastAction.icon}</x.div>}</Condition>
            </Button>
          )
        })}
      </x.div>
    </Condition>
  </S.$ToastContainer>
)
