import styled from '@xstyled/styled-components'

export const $ToastContainer = styled.divBox`
  display: flex;
  flex-direction: row;
  position: absolute;

  bottom: 10px;
  right: 10px;
  background-color: ${`grey.100`};
  padding: 10px;
  z-index: 10;
  border-radius: 5px;
  box-shadow: normal;
  gap: 20px;

  transition: transform 0.2s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.2s ease-in-out;
  will-change: transform, opacity;

  transform: 'scale(0.9)';
  transform-origin: bottom right;

  &[data-visible='true'] {
    transform: 'scale(1)';
    opacity: 1;
  }

  &[data-visible='false'] {
    transform: 'scale(0.9)';
    opacity: 0;
  }
`

export const $Content = styled.divBox`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const $IconContainer = styled.divBox`
  cursor: pointer;

  &:hover {
    path {
      fill: ${`grey.12`};
    }
  }
`
