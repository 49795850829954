import { defaultTheme } from '@xstyled/styled-components'

import { colors } from './colors'

export const theme = {
  ...defaultTheme,
  screens: {
    _: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
    xxxl: 1700,
    mobile: '640px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '1280px',
    wide: '1536px',
    ultrawide: '1700px',
  },
  space: {
    0: '0px',
    5: '5px',
    10: '10px',
    15: '15px',
    20: '20px',
    50: '50px',
  },
  sizes: {
    xs: 25,
    sm: 30,
    md: 34,
    sidebar: {
      width: 60,
    },
    get main() {
      return `calc(100% - ${this.sidebar.width}px)`
    },
  },
  colors: {
    white: '#FFFFFF',
    grey: colors.grey,
    red: colors.red,
    purple: colors.purple,
    blue: colors.blue,
    green: colors.green,
    pink: colors.pink,
    highlight: colors.highlight,
    labels: colors.labels,
    modes: {
      dark: {
        white: '#11121b',
        grey: {
          2: '#0F0F14',
          4: '#17171F',
          8: '#1E1E27',
          12: '#262631',
          16: '#2E2E3B',
          24: '#383846',
          32: '#424250',
          40: '#4C4C5A',
          48: '#565664',
          64: '#77778a',
          88: '#afafb8',
          100: '#efeff6',
          text: '#efeff4',
        },
        purple: {
          4: '#191A30',
          8: '#212243',
          12: '#282852',
          16: '#2F3064',
          24: '#353572',
          32: '#3B3D80',
          48: '#5a5cc5',
          64: '#4c4fb0',
          100: '#4F52CC',
          text: '#FFFFFF',
        },
        red: colors.red,
        blue: colors.blue,
        green: colors.green,
        highlight: colors.highlight,
        labels: colors.labels,
      },
    },
    themes: {
      '0': '#FDDCF0',
      '1': '#E9DAF8',
      '2': '#DFE7F3',
      '3': '#D1F5F5',
      '4': '#CBF0DC',
      '5': '#E9F7CB',
      '6': '#FFF6CA',
      '7': '#FFD9CB',
      '8': '#EED8C8',
    },
  },
  fontSizes: {
    sm: 12,
    md: 14,
    lg: 18,
    xl: 24,
    xxl: 30,
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  shadows: {
    extraLight: '0px 2px 10px rgba(0, 0, 0, 5%)',
    light: '0px 3px 12px rgba(16, 17, 68, 9%)',
    normal: '0px 3px 12px rgba(0, 0, 0, 15%)',
    heavy: '0px 3px 12px rgba(0, 0, 0, 25%)',
    collapsedCard: '2px 4px 2px rgba(0, 0, 0, 20%)',
    button: '0px 1px 4px -1px rgba(0, 0, 0, 0.09)',
    left: '-2px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  zIndices: {
    exosphere: 100000,
    stratosphere: 10000,
    toast: 10,
    dialog: 2,
    overlay: 1,
  },
} as const

export type Theme = typeof theme
