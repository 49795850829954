import React from 'react'

import { Icons } from '@/assets'
import { useDebounce } from '@/shared/hooks'

import { Condition } from '../Condition'
import { Portal } from '../Portal'
import { ProgressBar } from '../ProgressBar'
import { Text } from '../Text'
import * as S from './BackgroundTaskToast.styled'

interface BackgroundTaskToastProps {
  message: string
  isVisible: boolean
  progress?: number
}

export const BackgroundTaskToast = ({ message, isVisible: initialVisible, progress }: BackgroundTaskToastProps) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(initialVisible || false)

  const debounceIsVisible = useDebounce(isVisible, 2500)

  React.useEffect(() => {
    setIsVisible(initialVisible)
  }, [initialVisible])

  return (
    <Condition when={isVisible || debounceIsVisible}>
      <Portal>
        <S.$ToastContainer data-visible={isVisible}>
          <S.$Content>
            <Text fontSize="12px" color="grey.12">
              {message}
            </Text>
            <Condition when={!!progress}>
              <ProgressBar
                progress={progress}
                containerStyles={{
                  display: 'flex',
                  bg: 'grey.64',
                  h: '5px',
                  opacity: progress && progress > 0 ? 1 : 0,
                }}
                progressStyles={{ bg: 'grey.4' }}
              />
            </Condition>
          </S.$Content>
          <S.$IconContainer onClick={() => setIsVisible(false)}>
            <Icons.CloseIcon width={20} height={20} />
          </S.$IconContainer>
        </S.$ToastContainer>
      </Portal>
    </Condition>
  )
}
