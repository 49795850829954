import React from 'react'
import { x } from '@xstyled/styled-components'

type ProgressBarProps = {
  className?: string
  progress?: number
  containerStyles?: SCProps<typeof x.div>
  progressStyles?: SCProps<typeof x.div>
}

export const ProgressBar = ({ progress = 0, className, containerStyles, progressStyles }: ProgressBarProps) => {
  return (
    <x.div className={className} h="10px" bg="grey.4" borderRadius="5px" overflow="hidden" {...containerStyles}>
      <x.div w={`${progress}%`} h="10px" maxW="100%" bg="grey.12" {...progressStyles} />
    </x.div>
  )
}
