import React, { Component, ErrorInfo, ReactNode } from 'react'

import { ErrorPage } from './ErrorPage'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  error?: Error
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error }
  }
  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    try {
      const Sentry = await import('@sentry/nextjs')
      Sentry?.captureException(error, {
        user: {
          id: localStorage?.getItem('user.id') as string,
          email: localStorage?.getItem('user.email') as string,
        },
        extra: {
          stack: errorInfo?.componentStack,
        },
      })
    } catch (error) {
      console.error('Sentry capturing error failed', { error })
    }
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage error={this.state?.error} onReload={() => this.setState({ hasError: false })} />
    }

    // Return children components in case of no error
    return <>{this.props.children}</>
  }
}
