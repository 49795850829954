import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React from 'react'
import axios from 'axios'

import { isClient, isProductionEnvironment } from '@/shared/utils'

import { decodeToken, getLocalUser } from '../utils/token'

interface ISegment {
  identify: (userId: string, traits?: any, options?: any) => void
  track: (event: string, properties?: any) => void
  load: () => void
  screen: (name: string, properties?: any) => void
  alias: (userId: string, previousId: string) => void
  group: (groupId: string, traits?: any) => void
  trackClick: (event: string, properties?: any) => void
  trackSubmit: (event: string, properties?: any) => void
  page: (name?: string, properties?: any) => void
}

const getSegmentPlugin = (): ISegment | undefined => {
  if (!isClient) return

  const { publicRuntimeConfig } = getConfig?.() || {}
  const SEGMENT_KEY = publicRuntimeConfig?.NEXT_PUBLIC_SEGMENT_KEY

  if (SEGMENT_KEY) {
    const globalContext = global as any
    const windowContext = window as any
    return (globalContext?.analytics || windowContext?.analytics) as ISegment
  }
}

export const identifyUser = (token: string): Promise<void> | void => {
  if (!isClient) return

  return new Promise((resolve) => {
    const segmentPlugin = getSegmentPlugin()

    const user = decodeToken(token)

    localStorage.setItem('user', JSON.stringify(user))

    const { id, name, email } = user || {}
    const traits = { user_id: id, name, email }

    segmentPlugin?.identify(id, { ...traits })

    setTimeout(() => resolve(), 500)
  })
}

export const identifyLocalUser = (): Promise<void> | void => {
  if (!isClient) return

  return new Promise((resolve) => {
    const segmentPlugin = getSegmentPlugin()
    const user = getLocalUser()

    if (!user) return resolve()

    const { id, name, email } = user
    const traits = { user_id: id, name, email }

    segmentPlugin?.identify(id, { ...traits })
    setTimeout(() => resolve(), 500)
  })
}

export const track = async (event: string, properties?: any, token?: string): Promise<void> => {
  if (!isClient) return

  const user = token ? decodeToken(token) : getLocalUser()

  if (!user?.name || !user?.email || !user?.organisationName) {
    return
  }

  await axios.post('/api/events', { eventName: event, user })

  return new Promise((resolve) => {
    const segmentPlugin = getSegmentPlugin()

    segmentPlugin?.track(event, { ...user, ...properties })
    setTimeout(() => resolve(), 200)
  })
}

export const trackSSR = async (event: string, token: string): Promise<void> => {
  try {
    if (!isProductionEnvironment) return

    const user = decodeToken(token)

    if (!user?.name || !user?.email || !user?.organisationName) return

    const baseUrl = process.env.NEXT_PUBLIC_CLIENT_URL
    const url = isClient ? '/api/events' : `${baseUrl}/api/events`
    await axios.post(url, { eventName: event, user }, { headers: { Authorization: `Bearer ${token}` } })
  } catch (error) {
    console.error(error)
  }
}

export const page = (path?: string, properties?: any): Promise<void> | void => {
  const segmentPlugin = getSegmentPlugin()

  return new Promise((resolve) => {
    segmentPlugin?.page({
      path,
      referrer: document?.referrer || '',
      search: location?.search || '',
      title: document?.title || '',
      url: location?.href || '',
      ...properties,
    })
    setTimeout(() => resolve(), 500)
  })
}

export const usePageTracking = (): void => {
  const router = useRouter()

  React.useEffect(() => {
    const handleRouteChange = async (url: string) => {
      await identifyLocalUser()
      await page(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])
}
