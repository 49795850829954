import React from 'react'
import { x } from '@xstyled/styled-components'

import { Loader } from '../Loader'
import * as S from './Button.styled'

export interface BaseButtonProps {
  text?: string | JSX.Element
  loading?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-inline' | 'shadow' | 'text' | 'danger'
  children?: React.ReactNode
  disabled?: boolean
  childrenPosition?: 'before' | 'after'
  type?: 'button' | 'submit' | 'reset'
}

export type ButtonProps = BaseButtonProps & SCProps<typeof S.Button> & SCProps<typeof x.div>

export const Button = ({
  text,
  loading = false,
  children,
  variant,
  childrenPosition = 'before',
  disabled,
  type,
  ...buttonProps
}: ButtonProps) => {
  return (
    <S.Button type={type} variant={variant} disabled={disabled} {...buttonProps}>
      {!loading && childrenPosition === 'before' && children}
      {!loading && text}
      {!loading && childrenPosition === 'after' && children}
      {loading && <Loader variant={variant as any} />}
    </S.Button>
  )
}
