import React from 'react'
import { IntercomProvider } from 'react-use-intercom'

import { INTERCOM_APP_ID } from '@/constants'
import { ThemeProvider } from '@/contexts'
import { AuthProvider } from '@/contexts/AuthContext'
import { Segment, Sentry } from '@/helpers'
import { Toast } from '@/kit'
import { UploadingFileToast } from '@/kit/BackgroundTask/UploadingFileToast'
import { useUpdateUserLastActiveAt } from '@/store'

type RootProps = { children: React.ReactNode; isAuthorized?: boolean; id?: string | number }

export default function Root(props: RootProps) {
  const { isAuthorized, children } = props

  Sentry.useInit()
  Segment.usePageTracking()

  useUpdateUserLastActiveAt()

  return (
    <ThemeProvider>
      <IntercomProvider appId={INTERCOM_APP_ID} shouldInitialize={true} autoBoot={false}>
        <AuthProvider isAuthorized={isAuthorized}>
          <div id="root">{children}</div>
          <Toast />
          <UploadingFileToast />
        </AuthProvider>
      </IntercomProvider>
    </ThemeProvider>
  )
}
