export const colors = {
  white: '#FFFFFF',
  grey: {
    2: '#FAFAFB',
    4: '#F5F5F8',
    8: '#ECECF0',
    12: '#E2E2E9',
    16: '#D9D9E1',
    24: '#C6C6D2',
    32: '#B3B3C3',
    40: '#9FA0B4',
    48: '#8C8DA5',
    64: '#666787',
    88: '#2D2E5B',
    100: '#101144',
    text: '#101144',
  },
  blue: {
    4: '#F0F6FF',
    8: '#E8F0FF',
    12: '#DFEBFF',
    16: '#D1E2FF',
    24: '#B8D2FD',
    32: '#90B7F9',
    48: '#5792F4',
    64: '#2770EA',
    100: '#0049C1',
    text: '#033586',
  },
  purple: {
    4: '#F2F3FF',
    8: '#ECECFF',
    12: '#E4E5FF',
    16: '#D9D9FF',
    24: '#C6C7FF',
    32: '#A4A5FF',
    48: '#7477F9',
    64: '#4B4EF0',
    100: '#292CD3',
    text: '#070980',
  },
  red: {
    4: '#FFF0F0',
    8: '#FFE8E8',
    12: '#FFDFDF',
    16: '#FFD1D1',
    24: '#FFB8B8',
    32: '#FF9090',
    48: '#FF5A5A',
    64: '#FF2D2D',
    100: '#FF0000',
    text: '#840e0e',
    error: '#D64758',
  },
  pink: {
    4: '#FFEFF5',
    8: '#FFE8F1',
    12: '#FFE0EC',
    16: '#FFD8E7',
    24: '#FFCEE1',
    32: '#FFC1D9',
    48: '#FFB3D0',
    64: '#FFA4C7',
    100: '#FF93BC',
  },
  green: {
    4: '#F0FDF5',
    8: '#E0FBE8',
    12: '#D1F9DB',
    16: '#BFF6C9',
    24: '#A8F1B0',
    32: '#8DEB97',
    48: '#5FE07A',
    64: '#3BCD5F',
    100: '#00B23C',
    text: '#0e5e2e',
  },

  highlight: {
    red: '#e88585',
    orange: '#e1b87a',
    yellow: '#e6cf58',
    green: '#7edb87',
    teal: '#79e3e3',
    blue: '#6fa1dd',
    indigo: '#8383e0',
    purple: '#b98ce7',
    pink: '#ea90c6',
  },
  labels: {
    red: '#E98181',
    orange: '#F0AE71',
    yellow: '#F2E358',
    green: '#7FCE8C',
    aqua: '#7FD8DE',
    blue: '#84B5F1',
    purple: '#9083CD',
    pink: '#E194D0',
    gray: '#D9D9E1',
  },
  widgets: {
    dark: '#1A1A1A',
    grey: '#5d5d5d',
    green: '#1eb17b',
    red: '#e74c3c',
    blue: '#3498db',
    orange: '#f39c12',
    purple: '#9b59b6',
    pink: '#e91e63',
    white: '#FFFFFF',
  },
  collaboration: {
    green: '#1eb17b',
    red: '#e53745',
    blue: '#3498db',
    yellow: '#f1c40f',
    orange: '#f39212',
    purple: '#9b59b6',
    pink: '#dd1978',
    aqua: '#1ab9bc',
    teal: '#16a085',
    dark: '#1A1A1A',
  },
  misc: {
    brick: {
      solid: '#C77070',
      tint: '#F4AEAE',
    },
    orange: {
      solid: '#F0AE71',
      tint: '#FBD1A8',
    },
    yellow: {
      solid: '#F9EC7D',
      tint: '#FFF6A7',
    },
    leaft: {
      solid: '#BEF494',
      tint: '#D2FBB2',
    },
    green: {
      solid: '#46D096',
      tint: '#86E8BF',
    },
    aqua: {
      solid: '#78E6ED',
      tint: '#B6F3F8',
    },
    blue: {
      solid: '#66BFEE',
      tint: '#9EDDFF',
    },
    purple: {
      solid: '#6B609F',
      tint: '#C9BEF4',
    },
    magenta: {
      solid: '#E18ED4',
      tint: '#F6CCF0',
    },
  },
}

export const HEX_TO_COLOR_MAPPINGS = {
  '#FDDCF0': 'pink',
  '#E9DAF8': 'purple',
  '#DFE7F3': 'steel',
  '#D1F5F5': 'aqua',
  '#CBF0DC': 'green',
  '#E9F7CB': 'leaf',
  '#FFF6CA': 'yellow',
  '#FFD9CB': 'orange',
  '#EED8C8': 'brown',
} as { [key: string]: string }
