import styled, { css, th } from '@xstyled/styled-components'

import { variant } from '@/shared/utils/styled'

const variants = {
  primary: css`
    div {
      border-color: ${th.color('white')} transparent transparent transparent;
    }
  `,
  secondary: css`
    div {
      border-color: ${th.color('grey.48')} transparent transparent transparent;
    }
  `,
  purple: css`
    div {
      border-color: ${th.color('purple.48')} transparent transparent transparent;
    }
  `,
  dashed: css`
    div {
      border-style: dashed;
      border-width: 1px;
      border-color: ${th.color('grey.48')} transparent transparent transparent;
    }
  `,
}

const loaderVariant = variant({
  default: 'primary',
  variants,
})

export type LoaderVariantProps = { readonly variant: keyof typeof variants }

interface LoaderProps {
  size?: number
}

const defaultLoaderSize = 24
const loaderPadding = 6

export const Container = styled.divBox<LoaderVariantProps & LoaderProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${({ size = defaultLoaderSize }) => size}px;
  height: ${({ size = defaultLoaderSize }) => size}px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size = defaultLoaderSize }) => size - loaderPadding}px;
    height: ${({ size = defaultLoaderSize }) => size - loaderPadding}px;
    border: ${(defaultLoaderSize - loaderPadding) / 9}px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${th.color('grey.48')} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  && {
    ${loaderVariant as any}
  }
`
