import { atom, useAtom } from 'jotai'
import _ from 'lodash'
import React from 'react'

export type LocalFile = {
  key: string
  uploaded: boolean
  progress: number
}

const filesAtom = atom<LocalFile[]>([])

export const useFiles = () => {
  const [filesState, setFiles] = useAtom(filesAtom)

  const addFile = React.useCallback(
    (key: string) => {
      setFiles((files) => _.uniqBy([...files, { key, uploaded: false, progress: 0 }], 'key'))
    },
    [setFiles],
  )

  const updateFile = React.useCallback(
    (key: string, update: Partial<LocalFile>) => {
      setFiles((currentFiles) => {
        const updatedFiles = currentFiles.map((file) => {
          if (file.key === key) {
            if (update.progress && update.progress < file.progress) return file

            return { ...file, ...update }
          }
          return file
        })

        return updatedFiles
      })
    },

    [setFiles],
  )

  const removeFile = React.useCallback(
    (key: string) => {
      setFiles((files) => files.filter((file) => file.key !== key))
    },
    [setFiles],
  )

  const clearAllFiles = React.useCallback(() => {
    setFiles([])
  }, [setFiles])

  const hasUploadingFiles = React.useMemo(() => {
    return filesState.some((file) => file.progress < 100)
  }, [filesState])

  const isFileUploading = React.useCallback(
    (key?: string) => {
      if (!key) return false

      const file = filesState.find((file) => file?.key === key)
      if (!file) return false

      return file?.progress < 100
    },
    [filesState],
  )

  const files = React.useMemo(() => filesState, [filesState])

  return { files, addFile, hasUploadingFiles, updateFile, clearAllFiles, removeFile, isFileUploading }
}

export const useFilesProgressHandler = () => {
  const { addFile, updateFile } = useFiles()

  const handleUploadProgress = React.useCallback(
    (key: string, progress: number) => {
      if (progress === 0) {
        addFile(key)
      } else if (progress > 0 && progress < 100) {
        updateFile(key, { progress })
      } else if (progress === 100) {
        updateFile(key, { progress, uploaded: true })
      }
    },
    [updateFile, addFile],
  )

  return handleUploadProgress
}
