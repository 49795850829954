import React from 'react'

export interface ConditionProps {
  when: boolean
  children: any
  fallback?: React.ReactNode | JSX.Element | null
}

export const Condition = ({ when, fallback = null, children }: ConditionProps) => {
  return when ? children : fallback
}
