import { x } from '@xstyled/styled-components'

import { Button, Condition, Separator, Text } from '@/kit'

interface ErrorPageProps {
  error?: Error
  onReload?: () => void
}

export const ErrorPage = ({ error, onReload }: ErrorPageProps) => {
  return (
    <x.div
      w="100vw"
      h="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      justifyItems="center"
      textAlign="center"
      position="absolute"
    >
      <x.h2 color="grey.100" fontWeight="semibold" fontSize="26px" mb="30px">
        Woops!
      </x.h2>
      <Text fontSize="14px" mb="30px">
        Something went wrong. Please try refreshing the page.
      </Text>
      <Button text="Reload" onClick={onReload} mb="50px" />
      <Condition when={!!error}>
        <Separator mb="30px" style={{ maxWidth: 300 }} />
        <Text maxWidth="300px">
          <x.span fontWeight="semibold">Error: </x.span>
          {error?.message}
        </Text>
      </Condition>
    </x.div>
  )
}
