import ReactDOM from 'react-dom'

import { isClient } from '@/shared/utils'

interface PortalProps {
  children: any
}

export const Portal = ({ children }: PortalProps): any => {
  if (!isClient) return null

  const root = document?.body
  if (!root) return null

  return ReactDOM.createPortal(children, root)
}
