import styled from '@xstyled/styled-components'

import { Text } from '../Text'

export const $ToastContainer = styled.divBox`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 52px;
  min-width: 220px;

  @media (max-width: 768px) {
    gap: 12px;
    width: 100%;
    flex-wrap: wrap;
  }
`

export const $ToastMessage = styled(Text)`
  color: ${'grey.100'};
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  white-space: nowrap;
  width: 100%;
`
