import { signIn, signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import React from 'react'

import { ISignInMutationVariables } from '@/generated/graphql'
import { Segment as SegmentHelper, Sentry as SentryHelper } from '@/helpers'
import { decodeToken, setLocalUser } from '@/utils'

interface LoginOptions {
  callbackUrl?: string
}

const AuthContext = React.createContext<{
  isAuthorized: boolean
  login: (values: ISignInMutationVariables, options?: LoginOptions) => Promise<any>
  logout: () => Promise<any>
}>({} as any)

export const AuthProvider = (props: { children: React.ReactNode; isAuthorized?: boolean }) => {
  const router = useRouter()
  const { status, data } = useSession()

  const isAuthorized = status === 'authenticated'

  const token = data?.accessToken

  const login = React.useCallback(
    async (values: ISignInMutationVariables, options?: LoginOptions) => {
      const res = await signIn('credentials', {
        email: values.email,
        password: values.password,
        redirect: !!options?.callbackUrl,
        callbackUrl: options?.callbackUrl,
      })

      if (res?.error) throw new Error(res.error)

      const redirectPath = (router.query?.redirect_to ?? encodeURIComponent('/feedback')) as string
      const decodedRedirectPath = decodeURIComponent(redirectPath)

      router.push(decodedRedirectPath)
    },
    [router],
  )

  const logout = React.useCallback(async () => {
    await SegmentHelper?.track('Signed Out')
    localStorage?.clear()

    await signOut({
      callbackUrl: `${window.location.origin}/auth/login`,
      redirect: true,
    })
  }, [])

  const handleSetup = React.useCallback(async (token: string) => {
    try {
      setLocalUser(token)

      await SegmentHelper?.identifyUser(token)

      SentryHelper?.identifyUser(decodeToken(token))
    } catch (error) {
      const Sentry = await import('@sentry/nextjs')
      Sentry?.captureException(error)
    }
  }, [])

  React.useEffect(() => {
    if (!token) return

    handleSetup(token)
  }, [token, handleSetup])

  return <AuthContext.Provider value={{ isAuthorized, login, logout }}>{props.children}</AuthContext.Provider>
}

export const useAuthContext = () => React.useContext(AuthContext)
