import styled, {
  BackgroundColorProps,
  ColorProps,
  DisplayProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  MaxWidthProps,
  SizingProps,
  SpaceProps,
  WidthProps,
  backgroundColor,
  color,
  compose,
  css,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  maxWidth,
  sizing,
  space,
  th,
  width,
  system,
} from '@xstyled/styled-components'

import { SizeVariantProps, sizeVariant, variant } from '@/shared/utils/styled'

export const BaseButton = styled.button.attrs<any>((props) => ({
  type: props.type || (!props.as ? 'button' : undefined),
  ref: props.innerRef,
}))`
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: center;
  text-decoration: none;
  pointer-events: auto;
  appearance: none;
  background-color: transparent;
  border: none;

  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, outline 0.2s ease-in-out,
    color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  will-change: background-color, box-shadow, outline, color, border-color;

  :disabled {
    pointer-events: none;
  }

  :hover {
    text-decoration: none;
  }

  :focus {
    outline: none;
  }
`

const variants = {
  none: {},
  primary: css`
    min-width: 80px;
    color: #fff;
    font-weight: normal;
    background-color: ${th.color('purple.48')};
    border-radius: 5px;
    border: 1px solid transparent;
    -webkit-appearance: button;
    box-shadow: ${th.shadow('button')};

    &:disabled {
      background-color: ${th.color('grey.24')};
    }
    &:hover {
      background-color: ${th.color('purple.64')};
    }
    &:focus-visible {
      background-color: ${th.color('purple.64')};
      outline: 2px solid ${th.color('purple.64')};
      outline-color: ${th.color('purple.64')};
      outline-offset: 5px;
      outline-style: auto;
    }
    &:active {
      background-color: ${th.color('purple.100')};
    }
  `,
  secondary: css`
    min-width: 80px;
    font-weight: normal;
    background-color: ${th.color('white')};
    border-radius: 5px;
    color: ${th.color('grey.100')};
    border: 1px solid ${th.color('grey.24')};
    -webkit-appearance: button;
    box-shadow: ${th.shadow('button')};

    &:disabled {
      color: ${th.color('grey.48')};
      border: 1px solid ${th.color('grey.16')};
    }
    &:hover {
      border: 1px solid ${th.color('grey.48')};
      background-color: ${th.color('grey.4')};
    }
    &:focus-visible {
      border: 1px solid ${th.color('grey.48')};
      background-color: ${th.color('grey.4')};
      outline: 2px solid ${th.color('purple.64')};
      outline-offset: 5px;
      outline-style: auto;
    }
    &:active,
    &[data-active='true'] {
      color: ${th.color('purple.64')};
      border: 1px solid ${th.color('purple.64')};
      background-color: ${th.color('grey.4')};

      div {
        color: ${th.color('purple.64')};
      }

      svg {
        stroke: ${th.color('purple.64')};
      }
    }
  `,
  tertiary: css`
    min-width: 80px;
    color: ${th.color('grey.48')};
    font-weight: normal;
    border-radius: 5px;

    &:disabled {
      color: ${th.color('grey.8')};
      border-color: ${th.color('grey.8')};
    }
    &:hover {
      background-color: ${'grey.4'};
    }
    &:focus-visible {
      color: ${th.color('grey.64')};
      background-color: ${th.color('grey.4')};
      outline: 2px solid ${th.color('purple.64')};
      outline-offset: 5px;
      outline-style: auto;
    }
  `,
  'tertiary-inline': css`
    min-width: 80px;
    color: ${th.color('grey.100')};
    font-weight: normal;
    border: 1px solid transparent;
    border-radius: 5px;

    span {
      text-decoration: underline;
    }

    &:disabled {
      color: ${th.color('grey.24')};
    }
  `,
  shadow: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${th.color('grey.40')};
    font-size: ${th.fontSize('md')};
    font-weight: normal;
    background-color: ${th.color('grey.4')};
    border-radius: 5px;

    &:hover {
      background-color: ${th.color('white')};
      box-shadow: ${th.shadow('extraLight')};
    }
  `,
  text: css`
    display: inline-block;
    color: ${th.color('grey.100')};
    text-decoration: underline;
  `,
  danger: css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${th.color('white')};
    font-weight: normal;
    background-color: ${th.color('red.error')};
    border-radius: 5px;
    border: 1px solid transparent;

    &:focus-visible {
      outline: 2px solid ${th.color('red.error')};
      outline-offset: 5px;
      outline-style: auto;
    }
  `,
}

const buttonVariant = variant({
  default: 'primary',
  variants,
})
type ButtonVariantProps = { readonly variant?: keyof typeof variants }

const buttonSystemProps = compose(
  space,
  display,
  sizing,
  color,
  fontWeight,
  fontSize,
  lineHeight,
  backgroundColor,
  width,
  maxWidth,
)

type ButtonSystemProps = ButtonVariantProps &
  SizeVariantProps &
  SpaceProps &
  WidthProps &
  MaxWidthProps &
  DisplayProps &
  SizingProps &
  ColorProps &
  FontWeightProps &
  FontSizeProps &
  LineHeightProps &
  BackgroundColorProps

export const Button = styled(BaseButton)<ButtonSystemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  line-height: normal;

  /* to take precedence over Link (<Button as={Link} />) */
  && {
    ${buttonVariant as any}
    ${sizeVariant as any}
    ${buttonSystemProps as any}
    ${system as any}
  }
`
