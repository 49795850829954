import { useColorMode } from '@xstyled/styled-components'
import React from 'react'

import { isClient } from '@/shared/utils'
import { useGetUserPreferences } from '@/store'

interface ThemeContextProps {
  isDarkMode: boolean
  isLightMode: boolean
}

const ThemeContext = React.createContext<ThemeContextProps>({} as any)

export function useThemeContext() {
  return React.useContext(ThemeContext)
}

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [{ preferences, hasRequestedData }] = useGetUserPreferences()

  const theme = preferences?.theme ?? 'system'

  const [color, setColorMode] = useColorMode()

  const isDarkMode = color === 'dark'
  const isLightMode = color === 'default'

  const prefersDark = isClient && window.matchMedia('(prefers-color-scheme: dark)')

  React.useEffect(() => {
    if (!prefersDark) return
    if (theme !== 'system') return

    setColorMode(prefersDark?.matches ? 'dark' : 'default')
  }, [prefersDark, theme, setColorMode])

  React.useEffect(() => {
    if (!hasRequestedData) return
    if (theme === 'system') return

    setColorMode(theme === 'light' ? 'default' : 'dark')
  }, [theme, hasRequestedData, setColorMode])

  return <ThemeContext.Provider value={{ isDarkMode, isLightMode }}>{children}</ThemeContext.Provider>
}
